import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, Text, IconButton, HStack, Center, Image, VStack, Spacer } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const FeatureSection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const featureData = [
    { name: 'Full Dex', icon: '2swap.png', description: 'Navigate the BLAST ecosystem effortlessly with our cutting-edge DEX, ensuring swift operations with any token.' },
    { name: 'Liquidity Pools', icon: '4liquidity_pools.png', description: 'Experience the liquidity pools on HyperBlast, where our pools compose an intricate melody, ensuring your tokens dance effortlessly through the market' },
    { name: 'Staking Platform', icon: '3staking.png', description: 'By staking HYPE, you can earn valuable rewards within the HyperBlast ecosystem. Your tokens work for you, effortlessly generating rewards in the web3 space' },
    { name: 'Token Generator', icon: '5token_generator.png', description: 'Revolutionize token creation with HyperBlast Generator. Effortlessly craft unique tokens using BLAST native functions, leaving coding complexities behind' },
    { name: 'Buy Bot', icon: '7buybot.png', description: 'Ascend to victory with HyperBlast Telegram Buy Bot—a robotic ally that notifies every token purchase, propelling your project to new heights effortlessly' },
    { name: 'More to announce', icon: '11leaderboard.png', description: 'We have more exciting features in the pipeline, and we`ll be sharing them with you soon' },
  ];

  const scrollLeft = () => {
    if (scrollPosition > 0) {
      setScrollPosition(scrollPosition - 1);
    } else {
      setScrollPosition(featureData.length - getNumBoxesToShow());
    }
  };

  const scrollRight = () => {
    if (scrollPosition < featureData.length - getNumBoxesToShow()) {
      setScrollPosition(scrollPosition + 1);
    } else {
      setScrollPosition(0);
    }
  };

  const getNumBoxesToShow = () => {
    if (window.innerWidth >= 1024) {
      return 4;
    } else if (window.innerWidth >= 768) {
      return 3;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScrollPosition(0);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const featureBoxStyle = {
    bg: 'yellow',
    borderColor: 'yellow',
    borderWidth: '2px',
    p: 5,
    color: 'black',
    minWidth: '270px',
    flex: '0 0 auto',
    marginRight: '10px',
    minHeight: '380px',
    position: 'relative',
    clipPath: 'polygon(0% 0%, 88% 0%, 100% 10%, 100% 100%, 88% 100%, 14% 100%, 0% 90%, 0% 20%)',
  };

  const iconStyle = {
    width: '50px',
    height: '50px',
    background: 'black',
    color: 'black',
    padding: '10px',
    borderRadius: '50%',
    marginBottom: '20px',
  };

  const containerStyle = {
    transform: 'scale(0.95)',
  };

  return (
    <Center minHeight="40vh" position="relative" zIndex={99}>
      <Box maxWidth="1440px" width="100%" margin="auto"  position="relative" p={1} mb={55} mt={-2} style={containerStyle}>
        <Box
          position="absolute"
          width="full"
          top={-2}
          left={0}
          ml={10}
          zIndex={1}
          style={{ display: window.innerWidth >= 768 ? 'block' : 'none' }}
        >
          <Image
            src="https://assets-global.website-files.com/65858ffa5f93fd84c2da4fdb/6585a9c442358b83e2810be2_Top.svg"
            alt="Top Border"
          />
        </Box>

        <Flex direction="column" align="center" p={25}>
          <Heading size="xl" mb={4} color="white" pt={35} pb={15} borderBottom={'1px solid yellow'}>
            ALL YOU NEED IN ONE PLATFORM
          </Heading>
          <Text fontSize="lg" fontFamily="Chakra Petch" pb={25} color="white">
            Working Actively to Reduce Friction with Web3: Making Operations on Blast Accessible to Everyone
          </Text>
          <Box h="2px" bg="yellow" my={4} />
          <HStack spacing={4} align="center" zIndex={100}>
            <IconButton aria-label="Scroll Left" icon={<ChevronLeftIcon />} onClick={scrollLeft} bg="yellow" color="black" mt="-55" _hover={{ bg: 'black', color: 'yellow', border: '1px solid yellow' }} />
            <Flex
              overflowX="hidden"
              style={{ gap: '15px' }}
              pb={50}
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              justifyContent={{ base: 'center', md: 'flex-start' }}
            >
              {featureData.slice(scrollPosition, scrollPosition + getNumBoxesToShow()).map((data, index) => (
                <Box key={index} {...featureBoxStyle} fontFamily="Chakra Petch">
                  <VStack alignItems="center">
                    <Image src={data.icon} alt={data.name} style={iconStyle} />
                    <Heading size="md" textTransform={'uppercase'}>{data.name}</Heading>
                    <Spacer />
                    <Box w="240px">
                      <Text mt={4}>{data.description}</Text>
                    </Box>
                  </VStack>
                </Box>
              ))}
            </Flex>
            <IconButton aria-label="Scroll Right" icon={<ChevronRightIcon />} onClick={scrollRight} bg="yellow" mt="-55" ml="-4" color="black" _hover={{ bg: 'black', color: 'yellow', border: '1px solid yellow' }} />
          </HStack>
        </Flex>

        <Box position="absolute" width="full" bottom={-2} left={0} ml={10} pt={50} zIndex={1}   style={{ display: window.innerWidth >= 768 ? 'block' : 'none' }}>
          <Image
            src="https://assets-global.website-files.com/65858ffa5f93fd84c2da4fdb/6585aa3d74763c1b565fa591_Bottom.svg"
            alt="Bottom Border"
          />
        </Box>
      </Box>
    </Center>
  );
};

export default FeatureSection;
