import React from 'react';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

const Header = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const launchApp = () => {
    window.location.href = 'https://app.hyperblast.io';
  };

  return (
    <Box as="header" top="0" left="0" right="0" background={'transparent'} p={4} >
      <Flex justify="space-between" align="center">
        <Image src="./hyperblast.svg" alt="HyperBlast Logo" w={isMobile ? '46%' : '13%'} />
        <Button
          background="black"
          border="2px solid black"
          color="white"
          fontFamily="Chakra Petch"
          borderRadius={0}
          height={{ base: "35px", md: "45px" }}
          onClick={launchApp}
          zIndex={999}
          _hover={{
            bg: "yellow",
            color: "black",
            border: "2px solid black"
          }}
        >
          Launch App
        </Button>
      </Flex>
    </Box>
  );
};

export default Header;
