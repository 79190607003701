import React from 'react';
import { Box, Flex, Heading, Text, Image, useBreakpointValue } from '@chakra-ui/react';

const CustomComponent = () => {
  const containerStyles = {
    backgroundImage: 'url("hyperblast-bg.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    backgroundSize: '50%',
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const imageBoxStyles = {
    flex: 1,
    minWidth: '65%',
    w: '100%',
    h: 'auto',
    borderradius: '0',
    position: 'relative',
    mt: isMobile ? 4 : 0,
    animation: 'pulseBoxShadow 2s infinite ease-in-out',
    transform: isMobile ? 'scale(0.95)' : 'scale(1.0)', // Escala un 5% más pequeña en dispositivos móviles
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      minHeight="76vh"
      mb="50"
      style={containerStyles}
    >
      <Box width="100%" maxWidth="1400px">
        <Flex
          direction={isMobile ? 'column' : 'row'}
          alignItems="center"
          p={4}
          mt={isMobile ? '-110' : '0'}
          borderRadius="md"
        >
          <Box flex={1} p={4} minWidth="35%" pr={isMobile ? 0 : 20}>
            <Heading size="xl" color="yellow" textTransform={'uppercase'} mb={isMobile ? '35' : '4'}>
              Stake Hype to earn Rev Share
            </Heading>
            <Text color="white" fontFamily="Chakra Petch" mb={isMobile ? '10' : '25'}>Stake HYPE and dive into a realm of rewards in ETH! By staking,
              you not only gain a share of the Rev Share generated by HyperBlast
              but also enjoy additional perks. We’ll recognize and reward
              long-term holders with larger stakes, granting them a substantial
              share in our prize pool. Your commitment is the key to unlocking
              greater benefits in our rewarding ecosystem</Text>
          </Box>
          <Box
            style={imageBoxStyles}
          >
            <Image
              src="hyperblast-staking.png"
              alt="Staking Platform of Hyperblast"
              maxW="100%"
              h="auto"
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default CustomComponent;
