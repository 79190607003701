import { useState, useEffect } from 'react';

// Initialize a global cache object
const cache = {};

function useAxiosQuery(url, cacheDuration = 30000) { // cacheDuration in milliseconds
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const now = Date.now();

      // Check cache first
      if (cache[url] && (now - cache[url].timestamp) < cacheDuration) {
        //console.log('Returning cached data for URL:', url);
        setData(cache[url].data);
        setLoading(false);
        setError(null);
      } else {
        // Data not in cache or cache is expired, fetch new data
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Network response was not ok (${response.statusText})`);
          }
          const jsonData = await response.json();
          
          // Update cache with new data
          cache[url] = {
            data: jsonData,
            timestamp: Date.now()
          };

          setData(jsonData);
          setError(null);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [url, cacheDuration]); // Depend on url and cacheDuration, so hook refetches data if they change

  return { data, loading, error };
}

  export default useAxiosQuery;