import { Box, Text, Stack, useBreakpointValue } from '@chakra-ui/react';
import { gql, useQuery } from "@apollo/client";
import React, { useState, useEffect } from 'react';
import useAxiosQuery from '../hooks/useAxiosQuery'
import { holdersQuery, apolloQuery } from '../constants/constants';
import { formatNumberWithCommas } from '../utils/formatters';

const StatCard = ({ title, value }) => (
  <Box
    borderRadius="5px"
    border="1px solid yellow"
    backgroundColor="black"
    color="white"
    p={4}
    m={2}
    width="300px"
    height="100px"
    textAlign="center" 
  >
    <Text fontSize="xl" fontWeight="bold" fontFamily="Chakra Petch" color={"yellow"}>
      {title}
    </Text>
    <Text fontSize="lg"  fontFamily="Chakra Petch" mt={2}>
      {value}
    </Text>
  </Box>
);

const TVL = () => {
  const mtValue = useBreakpointValue({ base: '-60px', md: '-51px' });

  const { loading, error, data: dataApollo } = useQuery(gql(apolloQuery), {
    pollInterval: 10000, // each 10 seconds //TODO config
    fetchPolicy: 'cache-first', // Bypass the cache
  }); 
  const { loadingHolders, errorHolders, data: holdersData} = useAxiosQuery(holdersQuery, 5000);

  const [loadedData, setLoadedData] = useState({
    tvl: '--',
    activeWallets: '--',
    pairCount: '--'    
  });

  useEffect(() => {
    if(dataApollo) {
      if(!error && !loading && dataApollo && dataApollo.uniswapFactories && dataApollo.uniswapFactories.length > 0 && 
        (dataApollo.uniswapFactories[0].totalLiquidityUSD) &&
        (dataApollo.uniswapFactories[0].pairCount)) 
      {
        loadedData.tvl = formatNumberWithCommas(parseInt(dataApollo.uniswapFactories[0].totalLiquidityUSD));
        loadedData.pairCount = formatNumberWithCommas(parseInt(dataApollo.uniswapFactories[0].pairCount));
      }      
    }
    if(!errorHolders && !loadingHolders && holdersData && holdersData.count) {
      loadedData.activeWallets = formatNumberWithCommas(holdersData.count);      
    }
    setLoadedData({...loadedData})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <Stack
      spacing={4}
      justifyContent="center"
      alignItems="center"
      mt={mtValue}
      direction={{ base: 'column', md: 'row' }} // Columna en mobile, fila en escritorio
    >
      <StatCard fontFamily="Chakra Petch" title="TVL" value={loadedData.tvl} />
      <StatCard fontFamily="Chakra Petch" title="ACTIVE WALLETS" value={loadedData.activeWallets} />
      <StatCard fontFamily="Chakra Petch" title="PAIRS" value={loadedData.pairCount} />
    </Stack>
  );
};

export default TVL;
