import React, { useEffect } from 'react';

const SendXForm = () => {
    useEffect(() => {
        if (!document.querySelector('script[src="//cdn.sendx.io/prod/Wgk5cLi4WvMtr76V3lt3QW.js"]')) {
            window._scq = window._scq || [];
            window._scs = window._scs || {};
            window._scs.teamId = "Wgk5cLi4WvMtr76V3lt3QW";

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = '//cdn.sendx.io/prod/Wgk5cLi4WvMtr76V3lt3QW.js';
            document.body.appendChild(script);
        }
    }, []);

    return <div className="sendx-form-4Mffd4fx9FolIjnKxtWptH"></div>;
};

export default SendXForm;
