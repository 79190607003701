import React from 'react';
import { Box, Center, Link, Icon } from "@chakra-ui/react";
import { FaTelegramPlane } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const Footer = () => (
  
  <Box position="relative" background="yellow" zIndex={99}>
    <Box
      position="absolute"
      top="0"
      mt="-1px"
      left="0"
      right="0"
      height="50px"
      background="black"
      zIndex={8}
      clipPath="polygon(0% 0%, 100% 0%, 50% 100%)"
    />
    <Center as="footer" color="white" p={2} zIndex={9} position="relative">
      <Box>
        <Link href="https://twitter.com/HyperBlastDex" isExternal>
          <Box
            display="inline-block"
            background="yellow" // Cambia el fondo a amarillo
            width="55px"
            height="55px"
            p={2}
            m={1}
            border="2px solid black" // Agrega un borde negro de 2px
          >
            <Icon as={FaXTwitter} boxSize={9} color="black" zIndex={10}/>
          </Box>
        </Link>
        <Link href="https://t.me/hyperblastdex" isExternal>
          <Box
            display="inline-block"
            background="yellow" // Cambia el fondo a amarillo
            width="55px"
            height="55px"
            p={2}
            m={1}
            border="2px solid black" // Agrega un borde negro de 2px
          >
            <Icon as={FaTelegramPlane} boxSize={9} color="black" zIndex={10} />
          </Box>
        </Link> 
        <Link href="https://linktr.ee/hyperblastdex" isExternal>
          <Box
            display="inline-block"
            background="yellow" // Cambia el fondo a amarillo
            width="55px"
            height="55px"
            p={2}
            m={1}
            border="2px solid black" // Agrega un borde negro de 2px
          >
            <div style={{display:'flex'}}><img src="linktree.png" alt="Linktree Logo" title="Linktree"/></div>
          </Box>
        </Link>         
        <Link href="https://www.geckoterminal.com/blast-sepolia-testnet/pools" isExternal>
          <Box
            display="inline-block"
            background="yellow" // Cambia el fondo a amarillo
            width="55px"
            height="55px"
            p={2}
            m={1}
            border="2px solid black" // Agrega un borde negro de 2px
          >
            <div style={{display:'flex'}}><img src="gecko-icon.png" alt="GeckoTerminal Logo" title="GeckoTerminal Pools"/></div>
          </Box>
        </Link>     
        <Link href="mailto:hyperblastteam@gmail.com" isExternal>
          <Box
            display="inline-block"
            background="yellow" // Cambia el fondo a amarillo
            width="55px"
            height="55px"
            p={2}
            m={1}
            border="2px solid black" // Agrega un borde negro de 2px
          >
            <div style={{display:'flex'}}><img src="email.png" alt="Email Logo" title="Email"/></div>
          </Box>
        </Link>                    
      </Box>
    </Center>
  </Box>
);

export default Footer;
