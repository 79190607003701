import React, { useState, useEffect } from 'react';
import { Box, Center, Heading, Text, Stack, Image } from '@chakra-ui/react';

const Tokenomics = () => {
  const [showImage, setShowImage] = useState(false);

  // Agregar un efecto de fade-in cuando se carga la imagen
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 1000); // Espera 1 segundo antes de mostrar la imagen
    return () => clearTimeout(timer);
  }, []);

  return (
    <Center minH="20vh">
      <Box
        p={10}
        m={4}
        width={"100%"}
        maxW="1220px"
        borderWidth="1px"
        borderRadius="none"
        borderColor="yellow"
        textAlign="center"
        marginBottom="130px"
      >
        <Stack
          spacing={4}
          justifyContent="right"
          alignItems="right"
          direction={{ base: 'column', md: 'row' }}
        >
          <Box flex="1" textAlign="left">
            <Heading size="xl" mb={5} color="yellow">
              TOKENOMICS
            </Heading>
            <Box h="2px" bg="yellow" my={4} />
          
            <Stack spacing={5} color="white" fontFamily="Chakra Petch"> 
              <Text fontSize="xl" paddingTop="15px">Ticker: $HYPE</Text>
              <Text fontSize="xl" paddingTop="15px">Total Supply: 10,000,000 HYPE</Text>
              <Stack spacing={2} direction="row" alignItems="center">
                <Text fontSize="xl" mt={5}>Chain:</Text>
                <Image src="blast.webp" alt="BLAST" width="90" height="21" mt={5}/>
              </Stack>
              <Text fontSize="xl" paddingTop="15px">TAX: 1/1</Text>
              <Text fontSize="xl" paddingTop="15px">Type: Deflationary</Text>
              <Text fontSize="xl" paddingTop="15px">CA: 0x9FE9991dAF6b9a5d79280F48cbb6827D46DE2EA4</Text>
            </Stack>
          </Box>

          <Box flex="1">
            <img
              src="tokenomics.svg"
              alt="Tokenomics HYPE"
              width="100%"
              height="100%"
              style={{ opacity: showImage ? 1 : 0, transition: 'opacity 0.5s ease-in-out' }}
            />
          </Box>
        </Stack>
      </Box>
    </Center>
  );
};

export default Tokenomics;
