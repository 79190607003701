export const subgraphUrl = 'https://api.studio.thegraph.com/query/62421/hyperblast/v0.0.11'
export const apolloQuery = `
  query queryLanding {
    uniswapFactories(first: 1) {
      id    
      pairCount
      totalLiquidityUSD        
    }
  }
`
export const holdersQuery = 'https://api.routescan.io/v2/network/mainnet/evm/81457/erc20/0x9FE9991dAF6b9a5d79280F48cbb6827D46DE2EA4/holders?count=true&limit=1';  