import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import GLOBE from 'vanta/dist/vanta.globe.min.js';
import SendXForm from './SendXForm';
import {
  Button,
  Link,
  Text,
  Box,
  Heading,
  Flex,
  ChakraProvider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FaRocket} from 'react-icons/fa';
import { FaMoneyBillTrendUp } from "react-icons/fa6";

const ButtonsComponent = ({ onOpen, onOpen2 }) => (
  <Box position="absolute" top="8" left="0" right="0" bottom="0" zIndex={20}>
    <Flex
      justifyContent={{ base: 'center', md: 'left' }}
      alignItems="left"
      h="100%"
      maxW={{ base: '100%', md: 'none' }}
      textAlign={{ base: 'center', md: 'left' }}
    >
      <Box className='Box' w={{ base: '100%', md: '60%' }} p={{ base: 4, md: 40 }}>
        <Heading className='Heading'
          as="h1"
          size="xl"
          fontFamily="Chakra Petch"
          fontWeight="700"
          color="black"
          mt={{ base: '15', md: '30' }}
          fontSize={{ base: '2rem', md: '5rem' }}
          pb={10}
          lineHeight={{ base: '2rem', md: '4.7rem' }}
        >
          EXCITING NEWS ON THE HORIZON: THE FIRST AMM DEX ON BLAST
        </Heading>
       

        <Button
  background="black"
  fontFamily="Chakra Petch"
  border="2px solid black"
  color="white"
  mt={{ base: 10, md: 0 }}
  mx={{ base: 'auto', md: 0 }}
  borderRadius={0}
  height={55}
  onClick={() => window.location.href = 'https://dapp.hyperblast.io'} // Actualizado para redirigir
  zIndex={1}
  _hover={{
    bg: 'black',
    color: 'white',
    border: '2px solid black',
  }}
>
<FaRocket style={{ marginRight: '10px' }} />  LAUNCH APP
</Button>

      </Box>
    </Flex>
  </Box>
);

const MyComponent = () => {
  const vantaRef = useRef(null);
  useEffect(() => {
    if (!vantaRef.current) return;

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const vantaEffect = GLOBE({
      el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 300.0,
      minWidth: 200.0,
      color: isMobile ? 0xbab300 : 0x000000,
      borderColor: isMobile ? 0xbab300 : 0x000000,
      backgroundColor: 0xfcfc03,
      scale: 1.0,
      scaleMobile: 2,
      size: 1.1,
      sizeMobile: 0.3,
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  const {
    isOpen: isFirstModalOpen,
    onOpen: onFirstModalOpen,
    onClose: onFirstModalClose
  } = useDisclosure();

  const {
    isOpen: isSecondModalOpen,
    onOpen: onSecondModalOpen,
    onClose: onSecondModalClose
  } = useDisclosure();

  return (
    <ChakraProvider>
      <Box
        ref={vantaRef}
        w="100%"
        h={{ base: '86vh', md: '94vh' }}
        mt={-100}
        pt={{ base: 1, md: 65 }}
        zIndex={-1}
      >
        <Flex justify="left" align="left" h="100%" zIndex={10}>
          <Box w={{ base: '100%', md: '60%' }} p={{ base: 4, md: 40 }}>
          </Box>
        </Flex>
      </Box>

      <ButtonsComponent onOpen={onFirstModalOpen} onOpen2={onSecondModalOpen} />

      <Modal isOpen={isFirstModalOpen} onClose={onFirstModalClose} size="lg">
        <ModalOverlay />
        <ModalContent
          bg="black"
          borderRadius={0}
          borderColor="yellow"
          borderWidth="2px"
          padding="25px"
          margin="auto"
        >
          <ModalHeader color="white"  fontFamily="Chakra Petch">Get Airdrop Updates Now! 🎈</ModalHeader>
          <ModalCloseButton onClick={onFirstModalClose} />
          <ModalBody>
            <SendXForm />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSecondModalOpen} onClose={onSecondModalClose} size="lg">
        <ModalOverlay />
        <ModalContent
          bg="black"
          borderRadius={0}
          borderColor="yellow"
          borderWidth="2px"
          padding="25px"
          margin="auto"
        >
          <ModalHeader color="white"  fontFamily="Chakra Petch">Contact Us 📢</ModalHeader>
          <ModalCloseButton onClick={onSecondModalClose} />
          <ModalBody>
          <Text fontFamily="Chakra Petch" color="white" fontWeight="600">
          📧 E-mail Contact:&nbsp;&nbsp;&nbsp;<Link href="mailto:investors@hyperblast.io" style={{textDecorationLine: 'underline'}}>investors@hyperblast.io</Link><br/><br/>
          🔵 Telegram Chat:&nbsp;&nbsp;&nbsp;<Link href="https://t.me/hyperblastinvestors" target="_blank" rel="noopener noreferrer" style={{textDecorationLine: 'underline'}}>t.me/hyperblastinvestors</Link>
          </Text>
          </ModalBody>
        </ModalContent>
      </Modal>

    </ChakraProvider>
  );
};

export default MyComponent;
