import React, { useEffect, useRef } from 'react';
import { Box, Center, Heading, Text } from '@chakra-ui/react';
import * as THREE from 'three';
import p5 from 'p5';
import TRUNK from 'vanta/dist/vanta.trunk.min.js';

const CTATop = () => {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect = TRUNK({
      el: vantaRef.current,
      THREE: THREE,
      p5: p5,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 100.00,
      minWidth: 100.00,
      scale: 0.50,
      scaleMobile: 1.00,
      color: 0x106160,
      backgroundColor: 0x000000,
      spacing: 1,
      chaos: 3
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
    <Center
      ref={vantaRef}
      minH="65vh"
      position="relative"
      p={1}
    >
      <Box
        p={8}
        m={0} // Eliminamos los márgenes en dispositivos móviles
        maxW={{ base: '100%', md: '680px' }} // Ajusta el ancho en dispositivos móviles
        borderRadius="none"
        textAlign="center"
      >
        {/* Resto del contenido de tu componente */}
        <Box h="2px" bg="yellow" my={4} />
        <Heading size="xl" mb={4} color="yellow">
          BLAST YOUR PROJECTS WITH OUR ALL-IN-ONE TOOL
        </Heading>
        <Text fontSize="lg" color="white" fontFamily="Chakra Petch">
          Introducing the most comprehensive DEX on BLAST! We've built a
          user-friendly platform that empowers anyone to launch their projects on
          this blockchain without the need for programming skills. Your project,
          your way – unleash your creativity effortlessly with our all-inclusive
          DEX.
        </Text>
      </Box>
    </Center>
  );
};

export default CTATop;
