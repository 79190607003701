import './App.css';
import './index.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Slide from './components/Slide';
import TVL from './components/TVL';
import CTATop from './components/CTATop';
import Tools from './components/Tools';
import Tokenomics from './components/Tokenomics';
import Staking from './components/Staking';
import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react';
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider } from '@apollo/client';
import { subgraphUrl } from './constants/constants';

const theme = extendTheme({
  fonts: {
    body: "'Chakra Petch', sans-serif",
    heading: "'Chakra Petch', sans-serif",
  },
  colors: {
    white: '#ffffff',
  },
});

// Create an instance of Apollo Client
const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: subgraphUrl, // Your GraphQL API URL is set here
  }),
  cache: new InMemoryCache()  
});

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <Header />
        <Slide />
        <TVL />
        <CTATop />
        <Tools />
        <Staking />
        <Tokenomics />
        <Footer />
      </ChakraProvider>
    </ApolloProvider>
  );
}

export default App;
